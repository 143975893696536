import React, { useState, useCallback, dangerouslySetInnerHTML } from 'react';
import { nanoid } from "nanoid";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CFormSelect } from '@coreui/react'
import './scss/MessageBubble.scss';
import './scss/CustomMessage.scss';
import Car from '../Car';


function MessageBubble({ messageBubble, onSend }) {
    

    const parseText = (messageBubble) => {
        if (messageBubble.text) {
            let parsedMessage = messageBubble.text.replace(
                /\[([^\]]+)\]\(([^)]+)\)/g,
                (match, text, url) => `<a href="${url}" target="_blank">${text}</a>`
            );
            // search for urls starting from https:// with no blank space before https://
            parsedMessage = parsedMessage.replace(
                /(\bhttps?:\/\/[^\s<]+)(?![^<]*>|[^<>]*<\/a>)/g,
                (url) => `<a href="${url}" target="_blank">${url}</a>`
              );
            // look for patterns like "**Weather**: Bali" and make it bold
            parsedMessage = parsedMessage.replace(
                /\*\*([^*]+)\*\*/g,
                (match, text) => `<b>${text}</b>`
            );
            return (<p style={{ marginBottom: 0 }} className='text-bubble' dangerouslySetInnerHTML={{__html: parsedMessage}}></p>);
        }
        return <></>;
    }

    const parseButtons = (messageBubble) => {
        if (messageBubble.buttons) {
            return (<>
                {
                    messageBubble.buttons.map((button) => {
                        let payload = button.payload;
                        return (<button key={nanoid()} className='message-bubble-button' onClick={() => onSend(payload, button.title)}>{button.title}</button>);
                    })
                }
            </>);
        }
        else if (messageBubble.custom && messageBubble.custom.custom_buttons) {
            return (<>
                {
                    parseText(messageBubble.custom.custom_buttons)
                }
                {
                    (messageBubble.custom.custom_buttons.text) ? <br/> : <></>
                }
                {
                    messageBubble.custom.custom_buttons.buttons.map((button) => {
                        let payload = button.payload;
                        if (button.type && button.type === "full") {
                            return (<button key={nanoid()} className='message-button full-size-button' onClick={() => onSend(payload, button.title)}>{button.title}</button>);
                        }
                        return (<button key={nanoid()} className='message-button' onClick={() => onSend(payload, button.title)}>{button.title}</button>);
                    })
                }
            </>);
        }
    }

    const parseCarousel = (messageBubble) => {
        if (messageBubble.custom && messageBubble.custom.carousel) {
            let images = messageBubble.custom.carousel.map(item => item);
            return (<>
            <Car images={images} />
            </>);
        }
    }

    const parseLocation = (message) => {
        if (message.custom && message.custom.location) {
            let mapsUrl = new URL("https://www.google.com/maps/embed/v1/place?key=AIzaSyB31fMQDaWSu0St0lpfomWAUExD7wHr_LE");
            let params = new URLSearchParams(mapsUrl.search);
            
            params.append("q", message.custom.location.name + ", " + message.custom.location.address);
            params.append("center", message.custom.location.latitude + "," + message.custom.location.longitude);
            params.append("zoom", "11");
            params.append("maptype", "roadmap");
            mapsUrl.search = params.toString();
            return (
                // Important! Always set the container height explicitly
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe width="100%" height="100%" style={{border: "0"}} loading="lazy" 
                        src={mapsUrl.toString()}></iframe>
                                            
                </div>
            );
        }
    }

    const parseFileDownload = (messageBubble) => {
        if (messageBubble.custom && messageBubble.custom.file_download) {
            let fileDownload = messageBubble.custom.file_download;
            let media_id = fileDownload.media_id;
            let datahub_port = 7000;
            let filename = fileDownload.save_as;
            let datahub_url = '';
            if (window.location.hostname === "localhost") {
                datahub_url = window.location.protocol + "//" + window.location.hostname + `:${datahub_port}`;
            }
            else {
                datahub_url = window.location.protocol + "//" + window.location.hostname + "/datahub";
            }
            datahub_url = datahub_url + "/media/download/" + media_id + "?filename=" + encodeURIComponent(filename);
            return (<>
                <a href={datahub_url} download={fileDownload.save_as}>
                    <button key={nanoid()} className='message-button file-download-button'>
                        <div className='file-download-container'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="7 10 12 15 17 10"></polyline>
                                <line x1="12" y1="15" x2="12" y2="3"></line>
                            </svg>
                                <p className='file-download-caption'>{fileDownload.caption}</p>
                        </div>
                    </button>
                </a>
            </>);
        }
        return <></>;
    }
    


    const parseMessageBubble = (messageBubble) => {
        return (<div className='message-bubble-container'>
            {
                parseCarousel(messageBubble)
            }
            {
                parseButtons(messageBubble)
            }
            {
                parseLocation(messageBubble)
            }
            {
                parseFileDownload(messageBubble)
            }
            {
                parseText(messageBubble)
            }
        </div>);
    };


    return (
        <>
            {parseMessageBubble(messageBubble)}
        </>
    );
}

export default MessageBubble;