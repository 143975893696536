import React, { useState, useCallback, dangerouslySetInnerHTML } from 'react';
import { nanoid } from "nanoid";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CFormSelect } from '@coreui/react'
import './scss/CustomMessage.scss';
import Car from '../Car';


function CustomMessage({ message, onSend }) {
    const parseLocation = (message) => {
        if (message.custom && message.custom.location) {
            let mapsUrl = new URL("https://www.google.com/maps/embed/v1/place?key=AIzaSyB31fMQDaWSu0St0lpfomWAUExD7wHr_LE");
            let params = new URLSearchParams(mapsUrl.search);
            
            params.append("q", message.custom.location.name + ", " + message.custom.location.address);
            params.append("center", message.custom.location.latitude + "," + message.custom.location.longitude);
            params.append("zoom", "11");
            params.append("maptype", "roadmap");
            mapsUrl.search = params.toString();
            return (
                // Important! Always set the container height explicitly
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe width="100%" height="100%" style={{border: "0"}} loading="lazy" 
                        src={mapsUrl.toString()}></iframe>
                                            
                </div>
            );
        }
    }

    const parseText = (message) => {
        if (message.text) {
            let parsedMessage = message.text.replace(
                /\[([^\]]+)\]\(([^)]+)\)/g,
                (match, text, url) => `<a href="${url}" target="_blank">${text}</a>`
            );
            // search for urls starting from https:// with no blank space before https://
            parsedMessage = parsedMessage.replace(
                /(\bhttps?:\/\/[^\s<]+)(?![^<]*>|[^<>]*<\/a>)/g,
                (url) => `<a href="${url}" target="_blank">${url}</a>`
              );
            // look for patterns like "**Weather**: Bali" and make it bold
            parsedMessage = parsedMessage.replace(
                /\*\*([^*]+)\*\*/g,
                (match, text) => `<b>${text}</b>`
            );
            return (<p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{__html: parsedMessage}}></p>);
        }
        return <></>;
    }

    const parseButtons = (message) => {
        if (message.buttons) {
            return (<>
                {
                    message.buttons.map((button) => {
                        let payload = button.payload;
                        return (<button key={nanoid()} className='message-button full-size-button' onClick={() => onSend(payload, button.title)}>{button.title}</button>);
                    })
                }
            </>);
        }
        else if (message.custom && message.custom.custom_buttons) {
            return (<>
                {
                    parseText(message.custom.custom_buttons)
                }
                {
                    (message.custom.custom_buttons.text) ? <br/> : <></>
                }
                {
                    message.custom.custom_buttons.buttons.map((button) => {
                        let payload = button.payload;
                        if (button.type && button.type === "full") {
                            return (<button key={nanoid()} className='message-button full-size-button' onClick={() => onSend(payload, button.title)}>{button.title}</button>);
                        }
                        return (<button key={nanoid()} className='message-button' onClick={() => onSend(payload, button.title)}>{button.title}</button>);
                    })
                }
            </>);
        }
    }

    const parseInlineButton = (message) => {
        let button = message.inline_button;
        if (button.title && (button.payload || button.href)) {
            if (button.payload) {
                return (<>
                    <button key={nanoid()} className='message-button' onClick={() => onSend(button.payload, button.title)}>{button.title}</button>
                </>);
            }
            else if (button.href) {
                return (<>
                    <a href={button.href} target="_blank" rel="noopener noreferrer">
                        <button key={nanoid()} className='message-button'>{button.title}</button>
                    </a>
                </>);
            }
        }
    }

    const parseDiscount = (message) => {
        if (message.custom && message.custom.discount) {
            return (<p style={{ marginBottom: 0 }}>
                {
                    message.custom.discount.map((item) => {
                        if (item.text) {
                            // strip nbsp from the text
                            item.text = item.text.replace(/&nbsp;/g, '');
                            //strip empty space at the beginning and end of the text
                            item.text = item.text.trim();
                            return <span key={nanoid()} dangerouslySetInnerHTML={{__html: item.text}}></span>;
                        }
                        else if (item.inline_button) {
                            // if this is last item then show it in newline
                            if (item === message.custom.discount[message.custom.discount.length - 1]) {
                                return (<><br/>{parseInlineButton(item)}</>);
                            }
                            return parseInlineButton(item)
                        }
                    })
                }
            </p>);
        }
    }

    const parseImage = (message) => {
        const path = 'https://console153.astcrm.com/rasaBotTest/image/'
        if (message.custom && message.custom.image && message.custom.image.url) {
            return (<>
                {
                    <img width="100%" height="100%" fill="none" style={{ borderRadius: "7px" }} src={path + message.custom.image.url + ".png"} />
                }
            </>);
        }
    }

    const parseCarousel = (message) => {
        if (message.custom && message.custom.carousel) {
            let images = message.custom.carousel.map(item => item);
            return (<>
            <Car images={images} />
            </>);
        }
    }

    const parseList = (message) => {
        if (message.custom && message.custom.list) {
            let listRows = message.custom.list.list_rows;
            let listHeader = message.custom.list.header;
            let listBody = message.custom.list.body;
            let listTitle = message.custom.list.list_title;
            let optionsList = [listTitle, ...listRows.map((row) => ({ "label": row.title, "value": row.payload, "key": nanoid() }))];
            return (<>
                <p style={{ marginTop: 0, marginBottom: 0 }}>{listHeader}</p>
                <p style={{ marginBottom: 0 }}>{listBody}</p>
                <CFormSelect style={{ marginTop: "10px", width: "100%" }} size="lg" className="mb-3" aria-label="Large select example"
                    onChange={(e) => onSend(e.target.value, e.target.options[e.target.selectedIndex].textContent)}
                    options={optionsList} />
            </>);
        }
    }

    const parseTable = (message) => {
        if (message.custom && message.custom.table) {
            let tableRows = message.custom.table;
            return (<>
                <table className="table table-hover">
                    {
                        tableRows.map((row) => {
                            let tr = row.tr;
                            return (<tr>
                                {
                                    tr.map((col) => {
                                        if (col.td || col.td == 0) {
                                            return (<td key={nanoid()} style={{columnSpan: col.colspan ? col.colspan : 1 }} >{col.td}</td>);
                                        }
                                        else if (col.th || col.th == 0) {
                                            return (<th key={nanoid()} style={{columnSpan: col.colspan ? col.colspan : 1 }} >{col.th}</th>);
                                        }
                                    })
                                }
                            </tr>);
                        })
                    }
                </table>
            </>);
        }
    }

    const parseFileDownload = (message) => {
        if (message.custom && message.custom.file_download) {
            let fileDownload = message.custom.file_download;
            let media_id = fileDownload.media_id;
            let datahub_port = 7000;
            let filename = fileDownload.save_as;
            let datahub_url = '';
            if (window.location.hostname === "localhost") {
                datahub_url = window.location.protocol + "//" + window.location.hostname + `:${datahub_port}`;
            }
            else {
                datahub_url = window.location.protocol + "//" + window.location.hostname + "/datahub";
            }
            datahub_url = datahub_url + "/media/download/" + media_id + "?filename=" + encodeURIComponent(filename);
            return (<>
                <a href={datahub_url} download={fileDownload.save_as}>
                    <button key={nanoid()} className='message-button file-download-button'>
                        <div className='file-download-container'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="7 10 12 15 17 10"></polyline>
                                <line x1="12" y1="15" x2="12" y2="3"></line>
                            </svg>
                                <p className='file-download-caption'>{fileDownload.caption}</p>
                        </div>
                    </button>
                </a>
            </>);
        }
        return <></>;
    }
    


    const parseMessage = (message) => {
        return (<>
            {
                parseText(message)
            }
            {
                parseCarousel(message)
            }
            {
                parseButtons(message)
            }
            {
                parseDiscount(message)
            }
            {
                parseTable(message)
            }
            {/* {
                parseImage(message)
            } */}
            {
                parseList(message)
            }
            {
                // message.custom && message.custom.image && message.custom.image.caption ? <p style={{ marginBottom: 0 }} >{message.custom.image.caption}</p> : ""
            }
            {
                message.custom && message.custom.type && message.custom.type === "date" ? <DatePicker label={message.custom.label} disablePast defaultValue={dayjs()} onChange={(newValue) => onSend(dayjs(newValue).format('DD.MM.YYYY'))} /> : <></>
            }
            {
                parseLocation(message)
            }
            {
                parseFileDownload(message)
            }
        </>);
    };


    return (
        <>
            {parseMessage(message)}
        </>
    );
}

export default CustomMessage;