import React, { useCallback, useEffect, useState, useRef } from 'react';
import bubble from './dodo.png';
import logo from './powered-logo.png';
// import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import WidgetContainer from "./Components/WidgetContainer";
import './App.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Cookies from "js-cookie";
function App() {
  const initialRender = useRef(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const vtigerUrl = "https://console153.astcrm.com/comchannel/webchat_webhook.php"
  const rasaDirectUrl = 'http://192.168.0.208:5007/webhooks/webchat/webhook'
  const einbotUrl = 'https://dev.einbot.com/datahub/rasa/proxy'
  // const einbotUrl = 'http://localhost:7000/rasa/proxy'
  const [messageBubbleText, setMessageBubble] = useState(<>Click here for Climate friendly travel offers<br/></>);
  const [isBouncing, setIsBouncing] = useState(false);
  const [isWalking, setIsWalking] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBouncing(true);

      // Turn off bouncing after 1 second (duration of the bounce animation)
      setTimeout(() => {
        setIsBouncing(false);
      }, 3000);
    }, 15000); // Change image to bounce every 30 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsWalking(true);

      setTimeout(() => {
        setIsWalking(false);
      }, 10000);
    }, 15000); // Change image to bounce every 30 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      // firefox doesn't support ancestorOrigins
      if ((window.location.ancestorOrigins == undefined && document.referrer == "") 
          || (window.location.ancestorOrigins != undefined && window.location.ancestorOrigins.length == 0))
      {
        setIsChatOpen(prevValue => true);
      }
      else {
        let einbotChatOpenCookie = Cookies.get('einbotChatOpen');
        if (einbotChatOpenCookie && einbotChatOpenCookie === 'true') {
          setIsChatOpen(prevValue => true);
        }
      }
    }
    
    // firefox doesn't support ancestorOrigins
    let ancestorUrl = "";
    if (window.location.ancestorOrigins != undefined && window.location.ancestorOrigins.length != 0) {
      ancestorUrl = window.location.ancestorOrigins[0];
    }
    else {
      ancestorUrl = document.referrer;
    }
    if(isChatOpen) {
      if(ancestorUrl != "") {
        window.parent.postMessage({type: 'OPEN'}, ancestorUrl);
      }
      Cookies.set('einbotChatOpen', 'true', { expires: 1, sameSite: 'None', secure: true });
    } else {
      if(ancestorUrl != "") {
        window.parent.postMessage({type: 'CLOSE'}, ancestorUrl);
      }
      Cookies.set('einbotChatOpen', 'false', { expires: 1, sameSite: 'None', secure: true});
    }
  }, [isChatOpen]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString); // doesn't work in IE, but who cares ;)

    const textBubble = urlParams.get("textbubble");
    if (textBubble && textBubble !== "") {
      setMessageBubble(<p className='chat-popup-overlay-bubble'>{textBubble}</p>);
    } else {
      setMessageBubble(<></>);
    }

    let einbotChatOpenCookie = Cookies.get('einbotChatOpen');
    if (einbotChatOpenCookie && einbotChatOpenCookie === 'true') {
      setIsChatOpen(prevValue => true);
    }
  }, []);
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mountTriggerStr = urlParams.get("mounttrigger");
  let urlRemote = urlParams.get("remote");
    const licenseid = urlParams.get("license");
    const hotelId = urlParams.get("hotel_id");
    if (licenseid && urlRemote) {
      //check if remote end with /, if not add it
      if (!urlRemote.endsWith("/")) {
        urlRemote = urlRemote + "/";
      }
      urlRemote = urlRemote + licenseid;
    }
    if (!urlRemote) {
      if (licenseid) {
        urlRemote = einbotUrl+"/"+licenseid;
      }
      else {
        urlRemote = vtigerUrl;
      } 
    }
    let additionalParamsDict = {};
    const excludeParams = ["remote", "mounttrigger"];
    for (let [key, value] of urlParams.entries()) {
      if (!excludeParams.includes(key)) {
        additionalParamsDict[key] = value;
      }
    }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div id='einbot-chat-widget' className="App">
      <div className={isChatOpen ? 'chat-popup open' : 'chat-popup'} id="myForm">
        {/* <MainContainer> */}
        <div id="chat-window-container-div" className="chat-window-container">
          <WidgetContainer
            remoteUrl={urlRemote}
            additionalParams={additionalParamsDict}
            mountTrigger={mountTriggerStr}
            setIsChatOpen={setIsChatOpen}
            setMessageBubble={setMessageBubble}
            isChatOpen={isChatOpen} />
        </div>
        <div className='powered-by'>
        <a className='einbot-com-link' href="https://www.einbot.com" target="_blank" rel="noopener noreferrer">
          <p>Powered by</p>
          </a>
        <a className='einbot-com-link' href="https://www.einbot.com" target="_blank" rel="noopener noreferrer">
          <img src={logo} className='powered' />
          </a>
        </div>
        {/* </MainContainer> */}
      </div>
      <div className={`chat-popup-overlay ${isChatOpen || isBouncing || window.innerWidth < 500 ? 'chat-popup-overlay-close' : 'chat-popup-overlay-open'}`}>
      <div>{messageBubbleText}</div>
      </div>
      <div className={isChatOpen ? 'bubble-container bubble-container-close' : 'bubble-container bubble-container-open'}>
        <div className='bubble-div'>
          <img src={bubble} onClick={() => setIsChatOpen(!isChatOpen)} className={`App-logo ${isBouncing ? 'bounce' : ''} ${isWalking ? 'walk' : ''}`} alt="logo" />
        </div>
      </div>
    </div>
    </LocalizationProvider>
);
}

export default App;
