import React, { useState, useEffect, useCallback, useRef } from "react";
import Widget from "./Widget";
import { nanoid } from "nanoid";
import CustomMessage from "./CustomMessage";
import MessageBubble from "./MessageBubble";
import { Message, Avatar, TypingIndicator, MessageSeparator, AvatarProps } from "@chatscope/chat-ui-kit-react";
import bubble from '../dodo.png';
import Cookies from "js-cookie";
import './scss/WidgetContainer.scss';


function WidgetContainer({ remoteUrl, additionalParams = {}, mountTrigger = "", setIsChatOpen, setMessageBubble, isChatOpen }) {

    const [messages, setMessages] = useState([]);
    const [typingIndicatorRef, setTypingIndicatorRef] = useState(<></>);
    const [serverUnresponsive, setServerUnresponsive] = useState(false);
    const noDisplayMessage = "DO_NOT_SHOW";
    const noDisplaySkipCRMMessage = "DO_NOT_SHOW_SKIP_CRM";
    const [remoteName,setRemoteName] = useState("Dodo");

    const userId = useRef(nanoid());
    const outgoingMessageQueue = useRef([]);
    const msgListRef = useRef();

    const scrollMsgList = () => {
            msgListRef.current.scrollToBottom("auto");
    };

    const receiveMessage = async (serverUrl, methodType, requestBody, retry = false) => {
        try {
                if (methodType === "POST") {
                    if (retry === false) {
                        setTypingIndicatorRef(<TypingIndicator content="Dodo is typing" />);
                    }
                    else {
                        setTypingIndicatorRef(<TypingIndicator content="Connection Error. Retrying" />);
                    }
                }
                const response = await fetch(serverUrl, {
                    method: methodType,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: requestBody ? JSON.stringify(requestBody) : null
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (methodType === "POST"){
                    setTypingIndicatorRef(<></>);
                }
                else if (methodType === "GET")
                {   
                    console.log("Recieved history data", data);
                    if (messages.length != 0) {
                        return;
                    }
                }
                //TODO: evaluate this logic
                // .then(data => {
                //     if (methodType === "GET")
                //     {   
                //         console.log("Recieved history data", data);
                //         if (messages.length != 0) {
                //             return;
                //         }
                    // }
            console.log(data);
            data.map(msg => {
                if (msg.recipient_id || msg.sender === "bot") {
                    if (msg && msg.custom && msg.custom.new_section) {
                        setMessages(prevMessages => [...prevMessages, <MessageSeparator key={nanoid()} content={msg.custom.new_section} />]);
                        scrollMsgList();
                    }
                    else if (msg && msg.custom && msg.custom.message_bubble) {
                        setMessageBubble(<MessageBubble messageBubble={msg.custom.message_bubble} onSend={openChatAndQueue}/>);
                    }
                    else if (!(msg.custom && ((msg.custom.ticket && !msg.text )|| (msg.custom.image && !msg.custom.carousel)))) {
                        let incomingMessageComponent = constructIncomingMessage(msg);
                        setMessages(prevMessages => [...prevMessages, incomingMessageComponent]);
                        scrollMsgList();
                    }
                }
                else {
                    const outgoingMessageModel = {
                        _id: nanoid(),
                        payload: msg.text,
                        type: "html",
                        sender: "me",
                        direction: "outgoing",
                    };
                    const outgoingMessage = <Message key={nanoid()} model={outgoingMessageModel} />;
                    setMessages(prevMessages => [...prevMessages, outgoingMessage]);
                }
            });
        }
        catch(error) {
            setTypingIndicatorRef(<></>);
            console.error('Error sending message:', error);
            if (retry === false) {
                receiveMessage(serverUrl, methodType, requestBody, true);
            }
            else {
                console.log('Setting serverUnresponsive to true');
                setServerUnresponsive(true);
            }
            // else {
            //     let userid_cookie = nanoid();
            //     Cookies.set('userId', userid_cookie, { expires: 1, sameSite: 'None', secure: true });
            //     console.log('Reset userid as connection retry failed',userid_cookie);
            //     setUserId(userid_cookie);
            // }
        };
    }

    useEffect(() => {
        const asyncEffect = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString); // doesn't work in IE, but who cares ;)
            const greeting = urlParams.get("greeting");
            if (greeting && messages.length === 0) {
                const greetingMessage = constructIncomingMessage({ "text": greeting });
                setMessages(prevMessages => [...prevMessages, greetingMessage]);
            }
            if (additionalParams && additionalParams["remoteName"] && additionalParams["remoteName"] !== "") {
                setRemoteName(additionalParams.remoteName);
            }

            if (isChatOpen === true) {
                let license = urlParams.get("license");
                if (license == null) {
                    license = "default";
                }
                let userid_cookie_name = "userid_" + license;
                let userid_cookie = Cookies.get(userid_cookie_name);
                if (userid_cookie === undefined || userid_cookie === null || userid_cookie === "") {
                    // var Cookies2 = Cookies.noConflict();
                    userid_cookie = userId.current;
                    Cookies.set(userid_cookie_name, userid_cookie, { expires: 1, sameSite: 'None', secure: true });
                    console.log('Creating new userid and writing to cookies',userid_cookie);
                    userId.current = userid_cookie;
                }
                else {
                    console.log('Fetching existing userid from cookies', userid_cookie);
                    if (userid_cookie) {
                        userId.current = userid_cookie;
                        if (mountTrigger && mountTrigger !== "" && messages.length === 0) {
                            let historyUrl = remoteUrl + "/history/" + userid_cookie;
                            await receiveMessage(historyUrl, "GET", null);
                        }
                    }
                }
                let hotelid_cookie = Cookies.get('hotel_id');
                if (!hotelid_cookie || hotelid_cookie == undefined || hotelid_cookie === "") {
                    console.log('Creating new hotel_id and writing to cookies', additionalParams["hotel_id"]);
                    if (additionalParams && additionalParams["hotel_id"] && additionalParams["hotel_id"] !== "" && additionalParams["hotel_id"] !== "null") {
                        console.log('new hotel_id from input query', additionalParams["hotel_id"]);
                        hotelid_cookie = additionalParams["hotel_id"];
                        Cookies.set('hotel_id', hotelid_cookie, { expires: 1,  sameSite: 'none', secure:true });
                    }
                    if (mountTrigger && mountTrigger !== "") {
                        await handleSend(mountTrigger, noDisplayMessage);
                    }
                }
                else if (hotelid_cookie) {
                    console.log('Fetching existing hotel_id from cookies', hotelid_cookie);
                    if (hotelid_cookie && additionalParams["hotel_id"] && hotelid_cookie !== additionalParams["hotel_id"]) {
                        console.log('hotel_id changed from', hotelid_cookie, 'to', additionalParams["hotel_id"]);
                        if (mountTrigger && mountTrigger !== "") {
                            await handleSend(mountTrigger, noDisplayMessage);
                        }
                        hotelid_cookie = additionalParams["hotel_id"];
                        Cookies.set('hotel_id', hotelid_cookie, { expires: 1,  sameSite: 'none',secure: true });
                    }
                    else if (hotelid_cookie && (!additionalParams["hotel_id"] || additionalParams["hotel_id"] === "")) {
                        console.log('hotel_id not found in this page. But has', hotelid_cookie);
                        if (mountTrigger && mountTrigger !== "") {
                            await handleSend(mountTrigger, noDisplayMessage);
                        }
                        Cookies.remove('hotel_id', { sameSite: 'none', secure: true });
                    }
                }
                if (outgoingMessageQueue.current.length > 0) {
                    console.log('Sending queued messages');
                    console.log(outgoingMessageQueue.current);
                    await Promise.all(outgoingMessageQueue.current.map(async (msg) => {
                        await handleSend(msg.message, msg.displayMessage);
                    }));
                    outgoingMessageQueue.current = [];
                }
            }
            else {
                if (mountTrigger && mountTrigger !== "") {
                    await handleSend(mountTrigger, noDisplaySkipCRMMessage);
                }
            }
        };

        // if (mountTrigger && mountTrigger !== "") {
        //     handleSend(mountTrigger, noDisplayMessage);
        // }
        asyncEffect();
        return () => {
            setMessages(prevMessages => []);
            //setUserId(nanoid());
        }
    }, [isChatOpen]);

    const constructIncomingMessage = (msg) => {
        return (<Message key={nanoid()} model={{
            direction: "incoming",
            type: "custom"
        }}>
            <Avatar src={bubble} name="Dodo" className="avatar-class"/>
            <Message.CustomContent>
                <CustomMessage key={nanoid()} message={msg} onSend={handleSend}></CustomMessage>
            </Message.CustomContent>
        </Message>);
    }

    const restartSession = useCallback(() => {
        setMessages(prevMessages => []);
        let userid_cookie = nanoid();
        let license = "default";
        const urlParams = new URLSearchParams(window.location.search);
        let licenseid = urlParams.get("license");
        if (licenseid && licenseid !== "") {
            license = licenseid;
        }
        let userid_cookie_name = "userid_" + license;
        Cookies.set(userid_cookie_name, userid_cookie, { expires: 1, sameSite: 'None', secure: true });
        console.log('Reset userid as connection retry failed',userid_cookie);
        userId.current = userid_cookie;
        setServerUnresponsive(false);
        setTypingIndicatorRef(<TypingIndicator content="Einbot is reconnecting" />);
        if (mountTrigger && mountTrigger !== "") {
            handleSend(mountTrigger, noDisplayMessage);
        }
    }, [messages, mountTrigger, typingIndicatorRef, serverUnresponsive]);

    const openChatAndQueue = useCallback((message, displayMessage = null) => {
        if (isChatOpen === false) {
            setIsChatOpen(prevValue => true);
        }
        outgoingMessageQueue.current.push({ message: message, displayMessage: displayMessage });
    }, [isChatOpen]);

    const handleSend = useCallback((message, displayMessage = null) => {
        return new Promise((resolve, reject) => {
            const currentUserId = userId.current;
            if (!displayMessage || (displayMessage && displayMessage !== noDisplayMessage && displayMessage !== noDisplaySkipCRMMessage)) {
                const outgoingMessageModel = {
                    _id: nanoid(),
                    payload: displayMessage ? displayMessage : message,
                    type: "html",
                    sender: "me",
                    direction: "outgoing",
                };
                const outgoingMessage = <Message key={nanoid()} model={outgoingMessageModel} />;
                setMessages(prevMessages => [...prevMessages, outgoingMessage]);
            }
            additionalParams["displayMessage"] = displayMessage ? displayMessage : message;
            additionalParams["chatboxOpen"] = isChatOpen;
            const requestBody = {
                sender: currentUserId,
                message: message,
                metadata: additionalParams
            };
            if (displayMessage === noDisplaySkipCRMMessage) {
                requestBody["sender"] = "chatbubbleuser";
            }
            receiveMessage(remoteUrl, "POST", requestBody).then(resolve).catch(reject);
        }); 
    }, [messages, remoteUrl, additionalParams, isChatOpen]);

    return (<Widget 
        remoteName={remoteName} 
        messages={messages}
        onSend={handleSend}
        messageListRef={msgListRef}
        typingIndicatorRef={typingIndicatorRef}
        setIsChatOpen={setIsChatOpen}
        serverUnresponsive={serverUnresponsive}
        restartSession={restartSession}/>);

}

export default WidgetContainer;
