import React, { useEffect, useState } from 'react';
import './Car.scss';

// const imagesPath = {
//     minus: "https://images.vexels.com/media/users/3/131484/isolated/preview/a432fa4062ed3d68771db7c1d65ee885-minus-inside-circle-icon-by-vexels.png",
//     plus: "https://cdn3.iconfinder.com/data/icons/glypho-generic-icons/64/plus-big-512.png"
//   }

function Car({ images }) {
    const [imagesPathState, setImagesPathState] = useState([]);
    useEffect(() => {
        setImagesPathState(images)
    }, []);
    const path = 'https://console153.astcrm.com/rasaBotTest/image/';
    // let getImageName = (index) => imagesPathState.length > index ? path + imagesPathState[index].image_url + ".png" : '';
    let getImageName = (index) => {
        if (imagesPathState.length > index) {
          let imageUrl = imagesPathState[index].image_url;
          if (!imageUrl.toLowerCase().endsWith('.jpg') && !imageUrl.toLowerCase().endsWith('.png') && !imageUrl.toLowerCase().endsWith('.jpeg')) {
            imageUrl = path + imageUrl + ".png";
          }
          return imageUrl;
        } else {
          return '';
        }
      };
    let getImageCaption = (index) => {
        let parsedTextCaption = imagesPathState.length > index ? imagesPathState[index].caption.text : '';
        parsedTextCaption = parsedTextCaption.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            (match, text, url) => `<a href="${url}">${text}</a>`
        );
        return parsedTextCaption;
    };
    let getImageCaptionUrl = (index) => imagesPathState.length > index ? imagesPathState[index].caption.url : '';
    const [carIndex, setCarIndex] = useState(0);
    const incrementIndex = () => {
        setImageLoaded(false);
        setCarIndex((prevIndex) => (prevIndex + 1) % imagesPathState.length);
    };

    const decrementIndex = () => {
        setCarIndex((prevIndex) => {
            setImageLoaded(false);
            // If the previous index is 0, set to max - 1, otherwise decrement
            return prevIndex === 0 ? imagesPathState.length - 1 : prevIndex - 1;
        });
    };

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    if (!imageLoaded) {
        return (
            <>
                <div >
                    <img src="https://climatefriendlytravelclub.com/wp-content/uploads/2023/07/cftc-logo-1.png" style={{ height: '209px', width: '309px', objectFit: 'contain' }} />
                    <img src={getImageName(carIndex)} onLoad={handleImageLoad} style={{ display: 'none' }} />
                </div>
            </>
        );
    }

    return (
        <>
            <div className='car-container'>
                {imagesPathState.length > 1 ? <button className='car-next car-next-left' onClick={() => decrementIndex()}>
                    <svg className="car-menu-arrow" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><defs></defs>
                        <path d="M10.67 3.33L6 8 10.67 12.67" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </button> : ""}
                <img src={getImageName(carIndex)} />
                {imagesPathState.length > 1 ? <button className='car-next car-next-right' onClick={() => incrementIndex()}>
                    <svg className="car-menu-arrow" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><defs></defs>
                        <path d="M6 3.33L10.67 8 6 12.67" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </button> : ""}
            </div>
            {
                getImageCaption(carIndex) && getImageCaptionUrl(carIndex) ? <a className='car-link' href={getImageCaptionUrl(carIndex)} target="_blank" rel="noopener noreferrer">
                    <button className='car-button'>{getImageCaption(carIndex)}</button>
                </a> : ''
            }
            {

                getImageCaption(carIndex) && !getImageCaptionUrl(carIndex) ? <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{__html: getImageCaption(carIndex)}}></p> : ''
            }
        </>
    );
}
export default Car;