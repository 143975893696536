import {
    MainContainer,
    ChatContainer,
    ConversationHeader,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    AvatarProps,
    ArrowButton,
    InfoButton
} from "@chatscope/chat-ui-kit-react";
import React, { useCallback, useRef, useState } from 'react';
import bubble from '../dodo.png';
import Car from "../Car";
import { Button } from "@mui/material";
import './scss/Widget.scss';

function Widget({ remoteName = "", messages = [], onSend, messageListRef, typingIndicatorRef, setIsChatOpen, serverUnresponsive, restartSession }) {

    return (
        <ChatContainer className='chat-container'>
            <ConversationHeader>
                <Avatar src={bubble} name="Dodo" size="fluid" className="header-avatar-class" />
                <ConversationHeader.Content userName={remoteName} info="Active" />
                <ConversationHeader.Actions>
                    <Button onClick={() => setIsChatOpen(prevValue => !prevValue)}>
                    <svg className="chat-menu-arrow" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><defs></defs>
                            <path d="M4 4 L12 12 M4 12 L12 4" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </Button>
                </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList ref={messageListRef} typingIndicator={typingIndicatorRef}>
                {messages.map(message => {
                    return (message);
                }
                )}
                {/* <Message model={{
                    direction: "incoming",
                    type: "custom"
                }}>
                    <Avatar src={bubble} name="Akane" />
                    <Message.CustomContent>
                        <Car/>
                    </Message.CustomContent>
                </Message> */}
            </MessageList>        
            <MessageInput attachButton={false} placeholder="Type message here" onSend={onSend}>
            </MessageInput>
        </ChatContainer>
    );
}

export default Widget;
